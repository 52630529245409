<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="180px" :model="formLabelAlign">
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="事件标题："
              prop="name"
              :rules="[{ required: true, message: '请输入事件标题' }]"
            >
              <el-input
                placeholder="请输入标题"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :span="12"
            prop="department_id"
            :rules="[{ required: true, message: '请选择责任部门' }]"
          >
            <el-form-item label="责任部门：">
              <Bepartment
                class="form-inp"
                ref="Bepartment"
                v-model="formLabelAlign.department_id"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="事件类型：" prop="cate_id">
              <el-cascader
                class="form-inp"
                v-model="formLabelAlign.cate_id"
                :options="cate"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  children: 'items',
                  value: 'id',
                }"
                @change="dataChange"
              ></el-cascader>
            </el-form-item>
          </el-col>

          
          </el-row><el-row>
         
          <el-col :span="12">
            <el-form-item
              label="发生时间："
              prop="happen_time"
              :rules="[{ required: true, message: '请选择责发生时间' }]"
            >
              <el-date-picker
                class="form-inp"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="formLabelAlign.happen_time"
                type="datetime"
                align="right"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="地点："
              prop="place"
              :rules="[{ required: true, message: '请输入地点' }]"
            >
              <el-input
                placeholder="请输入地点"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.place"
              ></el-input>
            </el-form-item>
          </el-col>

        </el-row><el-row>

          <el-col :span="12">
            <el-form-item label="上报人：">
              <el-input
                placeholder="请输入上报人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.contact"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上报人电话：">
              <el-input
                placeholder="请输入上报人电话"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.contact_phone"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row><el-row>

          <el-col :span="12">
            <el-form-item label="事件对象："
             prop="case_type"
             :rules="[{ required: true, message: '请选择事件对象' }]"
             >
              <el-select
                v-model="formLabelAlign.case_type"
                placeholder="请选择事件对象"
                class="form-inp"
              >
                <el-option
                  v-for="item in case_type_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formLabelAlign.case_type == '学生'">
            <el-form-item
                label="请选择涉事学生"
                prop="student_ids"
                :rules="[{ required: true, message: '请选择涉事学生' }]"
              >
              <el-select
                v-model="formLabelAlign.student_ids"
                filterable
                multiple
                remote
                placeholder="请输入姓名查询"
                :remote-method="getStudentAndDepart"
                :loading="loading"
                class="form-select"
                >
                <el-option
                  v-for="item in studentList"
                  :key="item.id"
                  :label="item.truename"
                  :value="item.id">
                  <span style="float: left">{{item.truename }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{item.department }} {{item.class_name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formLabelAlign.case_type == '教职工'">
            <el-form-item
                label="涉事教职工："
                prop="teacher_ids"
                :rules="[{ required: true, message: '请选择涉事教职工' }]"
              >
              <el-select
                v-model="formLabelAlign.teacher_ids"
                filterable
                multiple
                remote
                placeholder="请输入姓名查询"
                :remote-method="getUserAndDept"
                :loading="loading"
                class="form-select"
                >
                <el-option
                  v-for="item in userList"
                  :key="item.ding_userid"
                  :label="item.nickname"
                  :value="item.ding_userid">
                  <span style="float: left">{{ item.nickname }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.department }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="事件详情：">
              <el-input
                type="textarea"
                placeholder="请输入事件详情"
                size="mini"
                class="form-textarea"
                v-model="formLabelAlign.detail"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="现场图片：">
              <uploadingimg v-model="formLabelAlign.img_List" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="视频或录音：">
              <uploadingVideo v-model="formLabelAlign.video_List" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { casessaveInfo, casessave, studentindex ,getUserAndDepartment} from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";
import uploadingimg from "../../components/uploading/uploading_img.vue";
import uploadingVideo from "../../components/uploading/uploading_Video.vue";
import uploadingFile from "../../components/uploading/uploading_File.vue";

export default {
  name: "student_redacy",
  components: {
    Bepartment,
    uploadingimg,
    uploadingFile,
    uploadingVideo,
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      cate: [],
      type_list: [],
      status_list: [],
      color_list: [],
      estimate_type: [],
      formLabelAlign: {
        name: "",
        department_id: "",
        place: "",
        detail: "",
        happen_time: "",
        from_type: "",
        case_type:"",
        money: "",
        hurt: "",
        dead: "",
        status: "",
        contact: "",
        contact_phone: "",
        level_color: "",
        level_type: "",
        score: 0,
        cate_id: "",
        attachment_List: [],
        video_List: [],
        img_List: [],
        student_ids:[],
        teacher_ids:[]
      },
      case_type_list:[
        {
          "id":"学生",
          "name":"学生"
        },{
          "id":"教职工",
          "name":"教职工"
        }
      ],
      studentList:[],
      teacherList:[],
      loading:false
    };
  },
  created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    getStudentAndDepart(query) {
      let that =this;
        if (query !== '') {
          that.loading = true;
          studentindex({truename:query}).then((res)=>{
            that.loading = false;
            let {data} =res.data
            that.studentList =data;
          })
        } else {
          that.studentList = [];
        }
    },
    getUserAndDept(query) {
        if (query !== '') {
          this.loading = true;
          getUserAndDepartment({name:query}).then((res)=>{
            this.loading = false;
            this.userList =res.data;
          })
        } else {
          this.userList = [];
        }
      },
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      casessaveInfo(froms).then((res) => {
        let { info, cate, color_list, estimate_type, status_list,type_list } =
          res.data;
        this.cate = cate;
        this.color_list = color_list;
        this.estimate_type = estimate_type;
        this.status_list = status_list;
        this.type_list = type_list;

        if (id) {
          let { formLabelAlign } = this;
          this.id = id;
          formLabelAlign.id = id;
          this.$refs.Bepartment.bepartment_List = info.department_id;
          formLabelAlign.name = info.name;
          formLabelAlign.detail = info.detail;
          formLabelAlign.department_id = info.department_id;
          formLabelAlign.status = info.status;
          formLabelAlign.dead = info.dead;
          formLabelAlign.hurt = info.hurt;
          formLabelAlign.money = info.money;
          formLabelAlign.from_type = info.from_type + "";
          formLabelAlign.level_color = info.level_color;
          formLabelAlign.level_type = info.level_type;
          formLabelAlign.score = info.score;
          formLabelAlign.case_type =info.case_type;
          formLabelAlign.cate_id = info.cate_id;
          formLabelAlign.place = info.place;
          formLabelAlign.happen_time = info.happen_time;
          formLabelAlign.contact = info.contact;
          formLabelAlign.contact_phone = info.contact_phone;
          if (info.attachment) {
            formLabelAlign.attachment_List = info.attachment.split(",");
          }
          if (info.vedio) {
            formLabelAlign.video_List = info.vedio.split(",");
          }
          if (info.images) {
            formLabelAlign.img_List = info.images.split(",");
          }
        }
      });
    },
    dataChange(event) {
      let events = event[event.length - 1];
      console.log(events);
      this.formLabelAlign.cate_id = events;
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          formLabelAlign.attachment = formLabelAlign.attachment_List.join(",");
          formLabelAlign.vedio = formLabelAlign.video_List.join(",");
          formLabelAlign.images = formLabelAlign.img_List.join(",");
          delete formLabelAlign.attachment_List;
          delete formLabelAlign.video_List;
          delete formLabelAlign.img_List;

          casessave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.$router.go(-1);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.form-select {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_users{
  width: 100%;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.form-textarea {
  width: 800px;
}
</style>